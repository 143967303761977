globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"f1853f863fc1e4b7688b92be2ac7b1b6d6af8df1"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import { initSentry } from "./sentry.config"
import {
  browserTracingIntegration,
  browserProfilingIntegration,
} from "@sentry/nextjs"

initSentry({
  integrations: [browserTracingIntegration(), browserProfilingIntegration()],
  tracePropagationTargets: ["paragraph.xyz/api", "api.paragraph.xyz"],
})
